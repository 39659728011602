import React from 'react';
import TimeLine from './TimeLine';
import CardButton from '../common/components/CardButton';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import ActionsDialog from '../dialogs/ActionsDialog';
import { primary } from '../theme';

export interface FloatingBottomBarProps {
  analysis: {
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      id: number,
      orthophoto_png: string,
      phase: string,
      index_data: {
        name: string,
        png: string,
        tiff: string,
        average: number,
        min: number,
        max: number,
        std_dev: number,
      }[],
    }[],
  }[];
  currentAnalysis?: {
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      id: number,
      phase: string,
    }[],
  };
  selectedIndex?: string;
  setCurrentAnalysis: (analysis: any) => void;
  setZonationDialogOpen: (open: boolean) => void;
  zonationAvailable?: boolean;
}

function FloatingBottomBar(props: FloatingBottomBarProps) {
  const [ actionsDialogOpen, setActionsDialogOpen ] = React.useState<boolean>(false);

  const style: React.CSSProperties = {
    position: 'absolute',
    bottom: '25px',
    left: 75,
    right: 8,
    height: 55,
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
  };

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1} style={style}>
        <TimeLine
          analysis={props.analysis}
          currentAnalysis={props.currentAnalysis}
          currentIndex={props.selectedIndex}
          onAnalysisSelected={(analysisUuid) => {
            props.setCurrentAnalysis(props.analysis.find((a) => a.uuid === analysisUuid) ?? null);
            if (analysisUuid) {
              const url = new URL(window.location.href);
              url.searchParams.set('analysis', analysisUuid);
              window.history.replaceState({}, document.title, url.toString());
            }
          }}
          contained
        />
        <CardButton
          text={t('Actions')}
          style={{
            width: 'calc((100% - 8px) / 2)',
            height: '100%',
            backgroundColor: actionsDialogOpen ? primary : undefined,
            color: actionsDialogOpen ? 'white' : undefined,
          }}
          iconButtonStyle={{
            color: actionsDialogOpen ? 'white' : undefined,
          }}
          showMore={!actionsDialogOpen}
          showLess={actionsDialogOpen}
          onClick={() => setActionsDialogOpen(true)}
        />
      </Stack>
      <ActionsDialog
        zonationAvailable={props.zonationAvailable}
        setZonationDialogOpen={props.setZonationDialogOpen}
        open={actionsDialogOpen}
        onClose={() => setActionsDialogOpen(false)}
      />
    </React.Fragment>
  );
}

export default FloatingBottomBar;
